
.presentation-canvas {
    position: relative;
    overflow:hidden;
    border: 1px solid black;
    border-radius: 4px;
    width: 54vw;
    height: 54vh;
    background-color: white;
}

.selection {
    margin-bottom: 1vw;
    width: 100%;
    display: flex;
    justify-content: space-around;
    background-color: rgb(197, 235, 251);
    padding: 5px 0px;
    border-radius: 3px;
    
}

.selection img {
    width: 1.5vw;
    margin-right: 3px;
}

.selection i {
    padding: .7vw 0px;
    
}

.selection button {
    background-color: rgb(197, 235, 251);
    border: 1px solid rgb(197, 235, 251);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: .7vw;
}

.fontsize-container {
    display: flex;
    align-items: center;
}

.fontsize-container {
    font-size: 1.5vw;
}

.button-add-page {
    background-color: rgb(197, 235, 251);
    border-radius: 3px;
    border: 1px solid rgb(197, 235, 251);
    margin-top: 12px;
    font-size: 1vw;
}

.color-dropdown button {
    display: flex;
    flex-direction: column;
    
    
}

.selection button:hover {
    background-color: rgb(129, 217, 255);
}

.compose-container {
    display: flex;
    flex-direction: column;
    margin: 1vh auto;
    padding-top: 1vw;
    padding-bottom: .5vw;
    padding-left: 15vw;
    padding-right: 15vw;
    align-items: center;
    background-color:  rgb(105, 208, 255); 
    border-radius: 5px;
    height: 100%;
    
 
}

.title-compose {
    margin-bottom: 1vw;
    text-align: center;
}


.presentation-title-input {
    border: 1px solid rgba(0, 0, 0, 0.26);
    border-radius: 3px;
    min-height: 30px;
    overflow-wrap: break-word;
    min-width: 28vw;
    margin-top: 1vw;
    text-align: center;
    
}

.add-like-button button {
    border: none;
    background-color: transparent;
    margin-top: .5vw;
    
}

/* .add-like-button fa-solid fa-heart {
    color: red 
} */

.save-button {
    width: 30vw;
    height: 5vh;
    margin-top: 2vh;
    background-color: rgb(202, 12, 12);
    border: 1px solid  rgb(202, 12, 12);
    border-radius: 3px;
    font-size: 2vw;
    font-weight: 700;
}

.color-dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.color-dropdown-content {
    display: none;
    position: absolute;
    background-color: #fdf9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 12px;
    z-index: 1;
    color: black;
    margin-top: 4.4vh;
}

.color-dropdown:hover .color-dropdown-content {
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    position: absolute;
}

.all-presentations-container {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100vh;
    background-color:  white; 
}

.all-presentations-container h2 {
    padding: .3vw;
    font-size: 1.7vw;
    font-weight: 700;
    margin-top: 1vw;
    
}

.presentation-container {
    display: flex;
    flex-direction: column;
    height: 98vh;
    padding-top: 8vw;
    margin-left: -1vw;
}

.presentation-show-container{
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: transparent;
    align-items: center;
    width: 50vw;
    padding: 2vw 0px;
    margin-left: 3.5vw;
}

.presentation-show-container h3 {
    font-size: 1.4vw;
    font-weight: 700;
    text-align: left;
   
    
}



.static-title {
    display: flex;
    align-items: left;
    text-align: left;
    background-color: transparent;
    padding: 1vw 0px;
    border-radius: 8px;
    width: 42vw;
    color: black;
    margin-left: 2vw;
}

.static-canvas {
    position: relative;
    overflow:hidden;
    border: 1px solid black;
    border-radius: 4px;
    width: 46vw;
    height: 46vh;
    background-color: white;
    margin-left: 2vw;

}

.like-comment-buttons {
    display: flex;
    align-items: center;
    color: black;
    margin-left: -.7vw;
}



.footer-toggle {
    display: flex;
    flex-direction: column;
 
    
}


.footer-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: left;
    width: 45vw;
    margin-left: 2.5vw;
    margin-top: .3vw;
    
}

.button-remove-ele{
    background-color: rgb(197, 235, 251);
    border-radius: 3px;
    border: 1px solid rgb(197, 235, 251);
    margin-top: 12px;
    font-size: 1vw;
    margin-right: 10px;
    margin-left:50px;
}

.button-remove-page{
    background-color: rgb(197, 235, 251);
    border-radius: 3px;
    border: 1px solid rgb(197, 235, 251);
    margin-top: 12px;
    font-size: 1vw;
    margin-left:10px;
}


.slide-number-container{
    display: inline;
    margin-left: 10px;
  
}

.up-arrow {
    margin-left: 22vw;
}

.rotate-container{
    display: flex;
    align-items: center;
}

.rotate-input{
    background-color: transparent;
    border: none;
    width: 35px;
    overflow-x: auto;
    text-align: center;
   
}
.rotate-input::-webkit-outer-spin-button,
.rotate-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.error-body{
    display: flex;
    justify-content: center;
    
}
.error-content{
    color: red;
    font-weight: 700;
}