.edit-button-presentation{
    background-color: rgb(196, 196, 242) !important;
    border-radius: 0.5vw;
}

.edit-button-presentation a{
    text-decoration: none;
    font-size: 1.2vw;
    color: black;
}

.delete-presentation-button{
    background-color: red;
    border-radius: .5vw;
    font-size: 1.2vw;
    margin-left: 5px;
}
.profile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 88vh;
    margin-top: 0;
    padding-left: 1vw;
    background-color:  white; 
}

.profile-container h2 {
    font-size: 2vw;
    margin-top: 2vw;
}