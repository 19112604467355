.nav-wrapper {
    width: 100vw;
    margin-top: 0px;
    background-color: white;
    height: 10vh;
}

.nav-container {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
}

.links-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 1vw;

}

.link-nav {
    color: black;
    font-family: 'Telex', sans-serif;
    text-decoration: none;
    margin-bottom: 1vw;
    margin-top: 1vw;
    z-index: 5;
}


.links-auth {
    display: flex;
    /* flex-direction: column; */
    margin: 2vh 1vw;
    
   
}

.link-auth {
    color: black;
    text-decoration: none;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    width: 10vw;
    background-color: transparent;
    border-radius: 2px;
    text-align: left;
    padding: 1vw;
}


.home-link {
    padding-left: 1.5vw;
    font-size: 2vw;
    font-weight: 700;
    color: black;
    text-decoration: none;
    text-align: center;
}

.home-link h1 {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.profile-dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.profile-button {
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    line-height: 1.25;
    align-items: center;
    font-size: 2vw;
    border-radius: 99999px;
    margin-top: 1vh;
    margin-right: .8vw;

  }

  .profile-button p {
    font-size: 1vw;
  }
  


.profile-dropdown:hover .dropdown-content {
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    width: 2vw;
    align-items: center;
  }
  
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fdf9f9;
    min-width: 16vw;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
   height: 30vh;
    color: black;
    transform: translateY(calc(5.6vw));
   
  }

  .dropdown-content button {
    background-color: transparent;
    margin-top: 1vw;
    border-radius: 3px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }

  .profile-links {
    display: flex;
    margin-right: 5vw;
    justify-content: center;
  }

  .compose-button {
    border-radius: 3px;
    background-color: transparent;
    border: 1px solid  transparent;
    width: 10vw;
    text-align: center;
    font-size: 1vw;
    line-height: 1.25;
    margin-top: 6vh;
    text-decoration: underline;

  }

  .logout-button button  {
    font-size: 1vw;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    border: none
  }

 


