.mainpage-wrapper {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    display: flex;
    justify-content: center;
    width: 100vw;
    background:  white;
    margin-left: 10vw;
    height: 88vh;

}

.mainpage-wrapper h3 {
    margin-top: 10vw;
    margin-bottom: 2vw;
    font-size: 1.5vw;
}

.mainpage-header {
    display: flex;
    flex-direction: column;
    margin-top: 20vh;
    align-items: center;
    margin-left: 5vw;
    margin-right: 10vw;
    width: 60vw;
    padding-top: 5vw;
}

.mainpage-heading h1 {
    font-size: 4vw;
    padding: 10px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    width: 40vw;
}

.mainpage-heading {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    display: flex;
    font-size: 1vw;
    flex-direction: column;
    align-items: left;
}

.side-line {
    border-bottom: 1px solid black;
   display: flex;
    width: 3vw;
}

.caro-wrapper {
    margin-top: 2vh;
    width: 74vw;
    height: 30vh;
    background-color:transparent;
    padding: 1vw;
    margin: 0px auto;
    padding-right: 1.5vw;
  
    
}

.caro-presentation-slide {
    border: 1px solid black;
    border-radius: 5px;
    margin-right: 2vw;
    width: 30vw;
    font-size: 2vw;
    min-height: 44vh;
    
}

.caro-canvas {
    width: 21vw;
    height: 21vh;
    background-color: white;
    position: relative;
    overflow:hidden;
    border: 1px solid black;
    border-radius: 4px;
    
}



.caro-container {
    display: flex;
   flex-direction: column;
    border-radius: 8px;
    background-color: rgb(249, 243, 133);
    align-items: center;
    width: 22vw;
  
}

.caro-title {
    font-size: 1vw;
    text-align: center;
    border-radius: 5px;
    width: 20vw;
    margin-top: .5vw;
 
}

.caro-presentation-details {
    margin: auto;
    font-size: 1vw;
}


*, *::before, *::after {
  box-sizing: border-box;
}


.wrapper {
  background-color: white;
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10vh 0px;
}

.carousel {
  position: relative;
  width: 100%;
  height: 50vh;
  width: 50vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 8vh;
 
}

.carousel__item {
  background-color: rgb(249, 243, 133);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: left;
  position: absolute;
  width: 25vw;
  padding: 2vw 1.5vw;
  opacity: 0;
  filter: drop-shadow(0 2px 2px #555);
  will-change: transform, opacity;
  -webkit-animation: carousel-animate-vertical 27s linear infinite;
          animation: carousel-animate-vertical 27s linear infinite;
}

.carousel__item:nth-child(1) {
  -webkit-animation-delay: calc(3s * -1);
          animation-delay: calc(3s * -1);
}

.carousel__item:nth-child(2) {
  -webkit-animation-delay: calc(3s * 0);
          animation-delay: calc(3s * 0);
}

.carousel__item:nth-child(3) {
  -webkit-animation-delay: calc(3s * 1);
          animation-delay: calc(3s * 1);
}

.carousel__item:nth-child(4) {
  -webkit-animation-delay: calc(3s * 2);
          animation-delay: calc(3s * 2);
}

.carousel__item:nth-child(5) {
  -webkit-animation-delay: calc(3s * 3);
          animation-delay: calc(3s * 3);
}

.carousel__item:nth-child(6) {
  -webkit-animation-delay: calc(3s * 4);
          animation-delay: calc(3s * 4);
}

.carousel__item:nth-child(7) {
  -webkit-animation-delay: calc(3s * 5);
          animation-delay: calc(3s * 5);
}

.carousel__item:nth-child(8) {
  -webkit-animation-delay: calc(3s * 6);
          animation-delay: calc(3s * 6);
}

.carousel__item:last-child {
  -webkit-animation-delay: calc(-3s * 2);
          animation-delay: calc(-3s * 2);
}

.carousel__item-head {
  border-radius: 50%;
  background-color: #d7f7fc;
  width: 90px;
  height: 90px;
  padding: 14px;
  position: relative;
  margin-right: -45px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}

.carousel__item-body {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 20px 16px 70px;
}

.title {
  text-transform: uppercase;
  font-size: 20px;
  margin-top: 10px;
}

@-webkit-keyframes carousel-animate-vertical {
  0% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
  3%, 11.1111111111% {
    transform: translateY(100%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
  }
  14.1111111111%, 22.2222222222% {
    transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }
  25.2222222222%, 33.3333333333% {
    transform: translateY(-100%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
  }
  36.3333333333% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: visible;
  }
  100% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes carousel-animate-vertical {
  0% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
  3%, 11.1111111111% {
    transform: translateY(100%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
  }
  14.1111111111%, 22.2222222222% {
    transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }
  25.2222222222%, 33.3333333333% {
    transform: translateY(-100%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
  }
  36.3333333333% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: visible;
  }
  100% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
}