li{
    display: block;
    font-size: 1vw;
    margin-top: 1vw;
}

li a {
    color: black;
    
}

li a.active{
    font-weight: bold;
}

ul {
    height: 65vh;
    overflow-y: scroll;
}


.slider{
    background-color: white;
    width: 100%;
    color: black;
    border-radius: 3px;
    grid-template-columns: 35% 65%;
    position: relative;
    display: grid;
    height: 98vh;
    margin-top: .5vw;
    margin-right: 1vw;
  


}

.content_container{
    padding: 40px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    background-color: white;
    height: 80vh;
    
}

.content_container h1 {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-bottom: 1.5vw;
    font-size: 2vw;
    font-weight: 700;
}

.slide_navigation h3{
    font-size: 1.5vw;
    margin-top: 1vw;
   
}

.slider .slides{
    overflow-y: auto;
    max-height: 88vh;
    scrollbar-width: none;
}

.slider .slides::-webkit-scrollbar{
    display: none;
}

.slider .slides .slide{
    height: 88vh;
    background-color: rgba(183, 230, 238, 0.997);
    display: flex;
    padding: 40px;
    color: #333;
    align-items: center;
    border-radius: 35px;
    margin-bottom: .5vw;
}



.slider .slides .slide:nth-child(even){
    background-color: rgb(212, 255, 251);
}