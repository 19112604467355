.likes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95vw;
    height: 10vh;
    background-color:  white; 
    padding-left: 1vw;
    
 
}

.likes-container h2 {
    margin-bottom: 2vw;
    font-size: 1.7vw;
    font-weight: 700;
    margin-top: 1vw;
    color: rgb(202, 12, 12);
}

.presentation-container button { 
    background-color: transparent;
    border: none;

}


#comment-icon {
    margin-top: .3vw;
}

.delete-like-button {
    background-color: rgb(196, 196, 242) !important;
    border-radius: 0.5vw;
    text-decoration: none;
    font-size: 1.2vw;
    color: black;
    width: 5vw;
   
}

