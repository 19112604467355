div.about-us {
    display: flex;
    flex-direction: column;
    margin-left: 3vw;
}

div.about-us a{
    margin-top: 8vh;
    font-size: 4vh;
    text-decoration: none;
    display: block;
}

div.about-us p{
    font-size: 2vh;
    margin-top: 2vh;
}