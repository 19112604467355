
.category-input{
    outline: 0px solid transparent;
    padding: 5px;
    border-radius: 5px;
    border: solid 1px transparent;
    background-color: rgba(0,0,0,.1);
    margin-bottom: .5vw;
}
.category-container{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 10px;
}

.category{
    display: flex;
    flex-wrap: wrap;
    width: 54vw;
   
}


.category-remove{
    border: none;
    background-color: transparent;
    width:25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 5px;
}
.category-remove i{
    color: transparent;
}

.category-remove{
    background-color: grey;
    width: 1.25vw;
    height: 1.25vw;
}
.category-remove i{
    color: black;
    font-size: .51vw;
   
}

.add-category{
    width: 20vw;
    height: 50px;
}

.heading-categories{
    margin-bottom: 1vw;
}

