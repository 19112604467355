.comments-container {
    background-color: white;
    border-radius: 3px;
    width: 46vw;
    padding: 1vw;
    margin-top: 1vw;
}

.comment-row {
    display: flex;
    margin-bottom: 1vw;
}

.comment-container {
    display: flex;
    text-align: left;
    font-size: 1vw;
}

.commenter {
    margin-right: 2vw;
    min-height: 2vw;
    width: 5vw;
    font-size: .8vw;
    overflow-wrap: break-word;
}

.comment-buttons {
    margin-left: 1vw;
}

.comment-buttons button{
    background-color: transparent;
    border: none;
    font-size: 1vw;
}

.comment-content {
    width: 30vw;
}

.comment-text {
    width: 30vw;
}
.inputcomment-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vw;
}

.inputcomment-container input {
    background-color: rgba(248, 245, 245, 0.842);
    border-radius: 5px;
    width: 25vw;
    border: 1px solid transparent;
    justify-content: center;
    overflow-wrap: normal;
    min-height: 2vw;
    font-size: 1vw;
}

.right-side {
    display: flex;
    align-items: center;
}

.left-side button {
    margin-left: 2vw;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid white;
    min-height: 2vw;

}

.user-info {
    margin-right: 2vw;
}

.comment-edit-body{
    display: flex;
    justify-content: space-between;
    width: 35vw;
    
}

.update-comment-input{
    background-color: rgb(196, 196, 242);
    border-radius: 5px;
    width: 25vw;
    border: 1px solid rgb(196, 196, 242);
    justify-content: center;
    overflow-wrap: normal;
    min-height: 2vw;
    font-size: 1vw;
}

.update-comment-buttons-body{
    display: flex;
}

.update-comment-buttons{
    background-color: transparent;
    border: none;
    
}