.session-form {
    display: flex;
    flex-direction: column;
    width: 25vw;
    height: 100%;
    margin: auto;
    box-shadow: rgba(143, 217, 251, 0.2) 0px 0px 20px;
    padding: 17px;
    
    
}

.session-form-button {
    width: 100%;
    background-color: rgba(197, 235, 251, 0.563);
    border-radius: 3px;
    padding: 12px 24px;
    line-height: 1.3em;
    border: 1px solid rgba(197, 235, 251, 0.563);

}

.session-form label {
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.session-form label input {
    min-height: 30px;
    background-color: rgba(197, 235, 251, 0.563);
    border: 1px solid rgba(197, 235, 251, 0.563);
    border-radius: 5px;
}

.session-form label span {
    margin-right: 20px;
    margin-bottom: 10px;
}

.session-form h2 {
    font-family: 'Telex', sans-serif;
    font-size: 34px;
    margin-bottom: 40px;
}

.bg {
    animation:slide 3s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg,  rgb(74, 193, 248) 50%, white 50%);
    bottom:0;
    left:-50%;
    opacity:.5;
    position:fixed;
    right:-50%;
    top:0;
    z-index:-1;
    height: 100vh;
  }
  
  .bg2 {
    animation-direction:alternate-reverse;
    animation-duration:4s;
  }
  
  .bg3 {
    animation-duration:5s;
  }
  
  .content {
    background-color:rgba(255,255,255,.8);
    border-radius:.25em;
    box-shadow:0 0 .25em rgba(0,0,0,.25);
    box-sizing:border-box;
    left:50%;
    padding:8vmin;
    position:fixed;
    text-align:center;
    top:55%;
    transform:translate(-50%, -50%);
  }
  
  h1 {
    font-family:monospace;
  }
  
  @keyframes slide {
    0% {
      transform:translateX(-25%);
    }
    100% {
      transform:translateX(25%);
    }
  }

input.session-form-button:hover {
  cursor: pointer;
}